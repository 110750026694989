import * as React from "react"
import Layout from '../components/layout'
// import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Subscribe from '../components/subscribe'

const PPSurveysPage = ({ data }) => {
	const page = data.wpPage;
	return (
		<Layout title="Privacy policy" subtitle="Surveys" breadcrumbs={[['Privacy policy - surveys','#']]}>
			<Seo post={page} />
			<section className="content">
				<div dangerouslySetInnerHTML={{ __html: page.content }}></div>
			</section>
			<Subscribe/>
		</Layout>
	)
}

export default PPSurveysPage


export const simple_page_query = graphql`
	query PPSurveysPageQuery {
		wpPage(databaseId: {eq: 53}) {
			content
			nodeType
			title
			uri
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
	}
`;